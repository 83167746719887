<template>
  <div>
    <div class="modal fade" id="modalDocumentTemplate">
      <div v-loading="loading" class="modal-dialog modal-lg shadow" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{ isUpdate ? "Chỉnh sửa mẫu phiếu" : "Tạo mới mẫu phiếu" }}</h5>
          </div>
          <div class="modal-body txt-grey-900">
            <label class="fs-14 fw-500">Tên Phiếu</label>
            <input v-model="data.name" class="form--input w-full mt-2 h-10" />

            <div v-for="(item, key) in form" :key="key" class="mt-6 row">
              <div v-if="item">
                <label class="fs-14 fw-500 mb-1">{{ item.label }}</label>
                <TiptapEditor v-if="item.editor === 1" :id="`${key}-docs`" :value="item.value" @setValue="value => (item.value = value)" />
                <input v-model="item.value" v-else class="form--input h-10" />
              </div>
            </div>
            <div class="mt-6 text-sm font-medium flex justify-end">
              <button @click="handleSubmit" class="flex items-center justify-center border-0 focus:outline-none mx-3 bg-blue-700 text-white rounded-md h-9 px-3 py-2">
                <div v-if="!isUpdate">Tạo mới</div>
                <div v-else>Cập nhật</div>
              </button>
              <button @click="showModalDocumentTemplate(false)" class="flex items-center justify-center border-0 focus:outline-none bg-blue-50 text-primary rounded-md h-9 px-3 py-2">
                <div>Hủy</div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
    <script>
import TiptapEditor from '@/components/Editors/Tiptap'
import { mixinDocumentTemplate } from '../../../utils/mixinDocumentTemplate'

export default {
  name: 'ModalDocumentTemplate',
  components: { TiptapEditor },
  props: {
    isUpdate: {
      type: Boolean,
      default: false
    },
    docTemData: {
      type: Object,
      default: function () {
        return {}
      }
    },
    docTemExample: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  mixins: [mixinDocumentTemplate],
  data () {
    return {
      form: {},
      loading: '',
      data: {}
    }
  },
  watch: {
    docTemData () {
      this.handleMapData()
    },
    docTemExample () {
      this.handleMapData()
    }
  },
  mounted () {
    this.handleMapData()
  },
  methods: {
    showModalDocumentTemplate (show) {
      window.$('#modalDocumentTemplate').modal(show ? 'show' : 'hide')
    },
    getDate (date) {
      if (!date) return ''
      return window.moment(date).format('DD/MM/YYYY')
    },
    handleMapData () {
      if (this.isUpdate) {
        this.form = this.docTemData?.content ? JSON.parse(this.docTemData.content) : {}
        this.data = { ...this.docTemData }
      } else {
        this.form = this.docTemExample?.content ? JSON.parse(this.docTemExample.content) : {}
        this.data = { ...this.docTemExample }
      }
    },
    handleSubmit () {
      if (this.isUpdate) {
        this.handleUpdateEmrTemplate()
      } else {
        this.handleCreateEmrTemplate()
      }
    },
    async handleCreateEmrTemplate () {
      if (!this.$globalClinic?.id) return
      try {
        const params = {
          name: this.data?.name,
          workspace_id: this.$globalClinic.id,
          emr_template_id: Number(this.$route.params.id),
          content: JSON.stringify(this.form),
          type: Number(this.$route.query.tab)
        }
        await this.$rf.getRequest('DoctorRequest').postCreateDocTemplate(params)
        this.form = this.docTemExample?.content ? JSON.parse(this.docTemExample.content) : {}
        this.data = { ...this.docTemExample }
        this.$toast.open({
          message: 'Tạo thành công',
          type: 'success'
        })
        this.$emit('onCreateSuccess')
      } catch (error) {
        console.log(error)
      }
    },
    async handleUpdateEmrTemplate () {
      if (!this.$globalClinic?.id || !this.docTemData?.id) return

      try {
        const params = {
          name: this.data.name,
          workspace_id: this.$globalClinic.id,
          emr_template_id: Number(this.$route.params.id),
          content: JSON.stringify(this.form),
          type: Number(this.$route.query.tab)
        }
        await this.$rf.getRequest('DoctorRequest').postUpdateDocTemplate(this.docTemData.id, params)
        this.$toast.open({
          message: 'Update thành công',
          type: 'success'
        })
        this.$emit('onUpdateSuccess')
      } catch (error) {
        console.log(error)
      }
    }
  }
}
</script>
    <style scoped></style>
