<template>
  <div class="text-black mb-3">
    <div v-if="!showDetail">
      <div class="mb-0 text-blue-800 text-base flex justify-end w-full">
        <div class="flex items-center">
          <span
            v-if="isAuthor"
            @click="showModalCreate"
            class="fs-14 fw-700 text-grey-900 cursor-pointer"
          >
            <svg
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              class="mr-2"
            >
              <path
                d="M8.99984 0.666748C7.35166 0.666748 5.7405 1.15549 4.37009 2.07117C2.99968 2.98685 1.93158 4.28834 1.30084 5.81105C0.670115 7.33377 0.505087 9.00933 0.82663 10.6258C1.14817 12.2423 1.94185 13.7272 3.10728 14.8926C4.27272 16.0581 5.75758 16.8517 7.37409 17.1733C8.9906 17.4948 10.6662 17.3298 12.1889 16.6991C13.7116 16.0683 15.0131 15.0002 15.9288 13.6298C16.8444 12.2594 17.3332 10.6483 17.3332 9.00008C17.3332 7.90573 17.1176 6.8221 16.6988 5.81105C16.28 4.80001 15.6662 3.88135 14.8924 3.10752C14.1186 2.3337 13.1999 1.71987 12.1889 1.30109C11.1778 0.882296 10.0942 0.666748 8.99984 0.666748ZM8.99984 15.6667C7.6813 15.6667 6.39237 15.2758 5.29604 14.5432C4.19971 13.8107 3.34523 12.7695 2.84064 11.5513C2.33606 10.3331 2.20404 8.99269 2.46127 7.69948C2.71851 6.40627 3.35345 5.21839 4.2858 4.28604C5.21815 3.35369 6.40603 2.71875 7.69924 2.46151C8.99245 2.20428 10.3329 2.3363 11.5511 2.84088C12.7692 3.34547 13.8104 4.19995 14.543 5.29628C15.2755 6.39261 15.6665 7.68154 15.6665 9.00008C15.6665 10.7682 14.9641 12.4639 13.7139 13.7141C12.4636 14.9644 10.768 15.6667 8.99984 15.6667ZM12.3332 8.16675H9.83317V5.66675C9.83317 5.44573 9.74538 5.23377 9.5891 5.07749C9.43282 4.92121 9.22085 4.83342 8.99984 4.83342C8.77883 4.83342 8.56687 4.92121 8.41059 5.07749C8.25431 5.23377 8.16651 5.44573 8.16651 5.66675V8.16675H5.66651C5.44549 8.16675 5.23353 8.25455 5.07725 8.41083C4.92097 8.56711 4.83317 8.77907 4.83317 9.00008C4.83317 9.2211 4.92097 9.43306 5.07725 9.58934C5.23353 9.74562 5.44549 9.83342 5.66651 9.83342H8.16651V12.3334C8.16651 12.5544 8.25431 12.7664 8.41059 12.9227C8.56687 13.079 8.77883 13.1667 8.99984 13.1667C9.22085 13.1667 9.43282 13.079 9.5891 12.9227C9.74538 12.7664 9.83317 12.5544 9.83317 12.3334V9.83342H12.3332C12.5542 9.83342 12.7662 9.74562 12.9224 9.58934C13.0787 9.43306 13.1665 9.2211 13.1665 9.00008C13.1665 8.77907 13.0787 8.56711 12.9224 8.41083C12.7662 8.25455 12.5542 8.16675 12.3332 8.16675Z"
                fill="#03267A"
              />
            </svg>
            Thêm Phiếu mới
          </span>
        </div>
      </div>
      <div class="text-black mb-3">
        <div class="mb-0 text-blue-800 text-base flex justify-end w-full">
          <div v-loading="loading" class="handleLoading">
            <el-table
              :data="docsTemplateList"
              :default-sort="{ prop: 'date', order: 'descending' }"
              style="width: 100%"
              row-class-name="text-black text-sm"
              header-cell-class-name="text-black text-base font-semibold"
              class="cursor-pointer"
              @row-click="goToViewDetail"
            >
              <el-table-column prop="name" label="Tên phiếu"></el-table-column>
              <el-table-column
                :formatter="
                (row) => {
                  return row.author &&  row.author.name;
                }
              "
                label="Người tạo"
              ></el-table-column>
              <el-table-column :formatter="getDateTime" label="Ngày tạo"></el-table-column>
            </el-table>
            <div class="flex justify-content-end mt-3">
              <AppPagination
                :paginationProps="pagination"
                @onChangePagination="getDocsTemplateList"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <DocumentTemplateDetail :emrTemplateData="emrTemplateData" />
    </div>
    <ModalDocumentTemplate
      @onCreateSuccess="()=>{
        showModalCreate(false)
        getDocsTemplateList()
      }"
      :docTemExample="docTemExample"
    />
  </div>
</template>
<script>
import { AppPagination } from '@/components/Common'
import DocumentTemplateDetail from './DocumentTemplateDetail.vue'
import ModalDocumentTemplate from './ModalDocumentTemplate.vue'

export default {
  name: 'DocumentTemplateList',
  components: {
    AppPagination,
    DocumentTemplateDetail,
    ModalDocumentTemplate
  },
  props: {
    emrTemplateData: Object
  },
  data () {
    return {
      loading: false,
      loadingDoctor: false,
      showDetail: false,
      docsTemplateList: null,
      pagination: {
        currentPage: 1,
        pageSize: 15,
        totalItems: 0
      },
      bmhcType: null,
      docTemExample: {}
    }
  },
  watch: {
    '$route.query.vId' () {
      this.handleDetail()
    },
    '$route.query.tab' () {
      this.getDocsTemplateList()
      this.handleGetDocumentJson()
    },
    showDetail (val) {
      if (!val) this.getDocsTemplateList()
    }
  },
  mounted () {
    this.handleDetail()
    this.handleGetDocumentJson()

    if (!this.showDetail) this.getDocsTemplateList()
  },
  computed: {
    isAdmin () {
      return (
        this.$globalClinic &&
        this.$user &&
        this.$user.doctor &&
        this.$globalClinic.doctor_id === this.$user.doctor.id
      )
    },
    isAuthor () {
      return this.$user && this.$user.id === this.emrTemplateData?.author_id
    }
  },
  methods: {
    showModalCreate (show) {
      window.$('#modalDocumentTemplate').modal(show ? 'show' : 'hide')
    },

    async handleDetail () {
      if (this.$route.query.vId) {
        this.showDetail = true
      } else {
        this.showDetail = false
      }
    },
    async getDocsTemplateList (data) {
      const id = this.$route.params.id
      this.loading = true
      const params = {
        workspace_id: this.$globalClinic.id,
        emr_template_id: id,
        limit: data?.pageSize || this.pagination.pageSize,
        page: data?.currentPage || this.pagination.currentPage,
        sort_by: 'desc',
        type: this.$route.query.tab
      }
      await this.$rf
        .getRequest('DoctorRequest')
        .getDocsTemplateList(params)
        .then(r => {
          const responseData = r.data?.data || {}
          this.docsTemplateList = responseData?.data || []
          this.pagination = {
            currentPage: responseData?.meta?.page || this.pagination.page,
            pageSize: responseData?.meta?.limit || this.pagination.pageSize,
            totalItems: responseData?.meta?.total || 0
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    goToViewDetail (item) {
      this.$router.push({
        query: {
          tab: this.$route.query.tab,
          vId: item.id,
          type: 'view'
        }
      })
    },
    getDateTime (row) {
      return row.created_at
        ? window.moment(row.created_at).format('DD/MM/YYYY')
        : ''
    },
    async handleGetDocumentJson () {
      if (!this.$globalClinic?.id || !this.$route.query.tab) return

      const params = {
        // wsid: this.$globalClinic.id,
        wsid: 128,
        dtype: Number(this.$route.query.tab)
      }

      const response = await this.$rf
        .getRequest('DoctorRequest')
        .getEmrDocumentTemplateJson(params)
      const responseData = response.data?.data || {}
      this.docTemExample = responseData
    }
  }
}
</script>
    <style lang="scss" scoped>
::v-deep {
  .modal {
    z-index: 2001 !important;
  }
  .handleLoading {
    z-index: 0;
    width: 100%;
  }
}
</style>
  