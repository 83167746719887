<template>
  <div>
    <div class="sticky mt-2 top-0 flex justify-content-end bg-white text-base font-semibold">
      <button
        v-if="isAuthor"
        class="btn border-none text-black px-3"
        @click="showModalDocumentTemplate(true)"
        :disabled="loading"
      >
        <div class="loading" v-if="loading">
          <div class="spinner-border text-primary" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
        <svg
          width="24"
          height="24"
          viewBox="0 0 19 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M16.6201 9.00044C16.4212 9.00044 16.2304 9.07946 16.0898 9.22011C15.9491 9.36076 15.8701 9.55153 15.8701 9.75044V14.2504C15.8701 14.4494 15.7911 14.6401 15.6504 14.7808C15.5098 14.9214 15.319 15.0004 15.1201 15.0004H4.62012C4.4212 15.0004 4.23044 14.9214 4.08979 14.7808C3.94913 14.6401 3.87012 14.4494 3.87012 14.2504V3.75044C3.87012 3.55153 3.94913 3.36076 4.08979 3.22011C4.23044 3.07946 4.4212 3.00044 4.62012 3.00044H9.12012C9.31903 3.00044 9.50979 2.92142 9.65045 2.78077C9.7911 2.64012 9.87012 2.44935 9.87012 2.25044C9.87012 2.05153 9.7911 1.86076 9.65045 1.72011C9.50979 1.57946 9.31903 1.50044 9.12012 1.50044H4.62012C4.02338 1.50044 3.45108 1.7375 3.02913 2.15945C2.60717 2.58141 2.37012 3.15371 2.37012 3.75044V14.2504C2.37012 14.8472 2.60717 15.4195 3.02913 15.8414C3.45108 16.2634 4.02338 16.5004 4.62012 16.5004H15.1201C15.7169 16.5004 16.2892 16.2634 16.7111 15.8414C17.1331 15.4195 17.3701 14.8472 17.3701 14.2504V9.75044C17.3701 9.55153 17.2911 9.36076 17.1504 9.22011C17.0098 9.07946 16.819 9.00044 16.6201 9.00044ZM5.37012 9.57044V12.7504C5.37012 12.9494 5.44914 13.1401 5.58979 13.2808C5.73044 13.4214 5.9212 13.5004 6.12012 13.5004H9.30012C9.39882 13.501 9.49667 13.4821 9.58805 13.4448C9.67942 13.4074 9.76254 13.3525 9.83262 13.2829L15.0226 8.08544L17.1526 6.00044C17.2229 5.93072 17.2787 5.84777 17.3168 5.75638C17.3549 5.66498 17.3745 5.56695 17.3745 5.46794C17.3745 5.36893 17.3549 5.2709 17.3168 5.17951C17.2787 5.08812 17.2229 5.00516 17.1526 4.93544L13.9726 1.71794C13.9029 1.64765 13.8199 1.59185 13.7285 1.55377C13.6372 1.5157 13.5391 1.49609 13.4401 1.49609C13.3411 1.49609 13.2431 1.5157 13.1517 1.55377C13.0603 1.59185 12.9773 1.64765 12.9076 1.71794L10.7926 3.84044L5.58762 9.03794C5.51811 9.10802 5.46311 9.19113 5.42579 9.28251C5.38847 9.37389 5.36955 9.47174 5.37012 9.57044ZM13.4401 3.30794L15.5626 5.43044L14.4976 6.49544L12.3751 4.37294L13.4401 3.30794ZM6.87012 9.87794L11.3176 5.43044L13.4401 7.55294L8.99262 12.0004H6.87012V9.87794Z"
            fill="#03267A"
          />
        </svg>
        Sửa phiếu
      </button>
    </div>
    <div class="mt-3">
      <p class="uppercase font-bold text-2xl text-black text-center">{{docData.name}}</p>
      <div v-for="(data , key) in docContent" :key="key">
        <p v-if="data.label" class="fs-18 font-bold text-black fontForAll">{{ data.label }}:</p>
        <p v-if="data.value" class="fs-18 text-black fontForAll" v-html="data.value"></p>
      </div>
    </div>
    <ModalDocumentTemplate
      v-if="docData"
      :isUpdate="true"
      :docTemData="docData"
      @onUpdateSuccess="()=>{
        showModalDocumentTemplate(false)
        handleGetDocumentTemplateDetail()
      }"
    />
  </div>
</template>

<script>
import { MENU_EMR_TEMPLATE } from '../../../utils/constants'
import { mixinDocumentTemplate } from '../../../utils/mixinDocumentTemplate'
import ModalDocumentTemplate from './ModalDocumentTemplate.vue'

export default {
  name: 'DocumentTemplateDetail',
  mixins: [mixinDocumentTemplate],
  props: {
    emrTemplateData: Object
  },
  components: { ModalDocumentTemplate },
  data () {
    return {
      loading: false,
      MENU_EMR_TEMPLATE,
      docData: {}
    }
  },
  watch: {
    '$route.query' () {
      if (this.$route.query.tab && this.$route.query.vId) {
        this.handleGetDocumentTemplateDetail()
      }
    }
  },
  computed: {
    docContent () {
      return this.docData?.content ? JSON.parse(this.docData.content) : {}
    },
    isAdmin () {
      return (
        this.$globalClinic &&
        this.$user &&
        this.$user.doctor &&
        this.$globalClinic.doctor_id === this.$user.doctor.id
      )
    },
    isAuthor () {
      return this.$user && this.$user.id === this.emrTemplateData?.author_id
    }
  },
  mounted () {
    this.handleGetDocumentTemplateDetail()
  },
  methods: {
    showModalDocumentTemplate (show) {
      window.$('#modalDocumentTemplate').modal(show ? 'show' : 'hide')
    },
    async handleGetDocumentTemplateDetail () {
      if (!this.$route.query.vId) return

      try {
        const response = await this.$rf
          .getRequest('DoctorRequest')
          .getDocTemplateDetail(this.$route.query.vId)
        this.docData = response.data?.data || {}
      } catch (error) {
        console.log(error)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .fontForAll * {
    font-family: "Times New Roman", "Tinos", Times, serif !important;
  }
}
</style>