<template>
  <div class="bg-white p-4 h-full" id="ElectronicMedicalRecords">
    <div class="h-full">
      <div class="flex justify-between items-center">
        <div class="flex w-full py-3 text-black text-xl font-bold">
          <div>{{ emrTemplateData.name }}</div>
          <!-- <div>-{{ emrTemplateData.author && emrTemplateData.author.name }}</div> -->
          <span
            v-if="emrTemplateData && isAuthor"
            @click="showModalUpdateEmrTemplate(true)"
            class="mx-3 cursor-pointer"
          >
            <svg
              width="18"
              height="19"
              viewBox="0 0 18 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15.75 9.95357C15.5511 9.95357 15.3603 10.0326 15.2197 10.1732C15.079 10.3139 15 10.5047 15 10.7036V15.2036C15 15.4025 14.921 15.5932 14.7803 15.7339C14.6397 15.8746 14.4489 15.9536 14.25 15.9536H3.75C3.55109 15.9536 3.36032 15.8746 3.21967 15.7339C3.07902 15.5932 3 15.4025 3 15.2036V4.70357C3 4.50466 3.07902 4.31389 3.21967 4.17324C3.36032 4.03259 3.55109 3.95357 3.75 3.95357H8.25C8.44891 3.95357 8.63968 3.87455 8.78033 3.7339C8.92098 3.59325 9 3.40248 9 3.20357C9 3.00466 8.92098 2.81389 8.78033 2.67324C8.63968 2.53259 8.44891 2.45357 8.25 2.45357H3.75C3.15326 2.45357 2.58097 2.69062 2.15901 3.11258C1.73705 3.53453 1.5 4.10683 1.5 4.70357V15.2036C1.5 15.8003 1.73705 16.3726 2.15901 16.7946C2.58097 17.2165 3.15326 17.4536 3.75 17.4536H14.25C14.8467 17.4536 15.419 17.2165 15.841 16.7946C16.2629 16.3726 16.5 15.8003 16.5 15.2036V10.7036C16.5 10.5047 16.421 10.3139 16.2803 10.1732C16.1397 10.0326 15.9489 9.95357 15.75 9.95357ZM4.5 10.5236V13.7036C4.5 13.9025 4.57902 14.0932 4.71967 14.2339C4.86032 14.3746 5.05109 14.4536 5.25 14.4536H8.43C8.5287 14.4541 8.62655 14.4352 8.71793 14.3979C8.80931 14.3606 8.89242 14.3056 8.9625 14.2361L14.1525 9.03857L16.2825 6.95357C16.3528 6.88385 16.4086 6.80089 16.4467 6.7095C16.4847 6.61811 16.5043 6.52008 16.5043 6.42107C16.5043 6.32206 16.4847 6.22403 16.4467 6.13263C16.4086 6.04124 16.3528 5.95829 16.2825 5.88857L13.1025 2.67107C13.0328 2.60077 12.9498 2.54498 12.8584 2.5069C12.767 2.46882 12.669 2.44922 12.57 2.44922C12.471 2.44922 12.373 2.46882 12.2816 2.5069C12.1902 2.54498 12.1072 2.60077 12.0375 2.67107L9.9225 4.79357L4.7175 9.99107C4.64799 10.0611 4.59299 10.1443 4.55567 10.2356C4.51835 10.327 4.49943 10.4249 4.5 10.5236ZM12.57 4.26107L14.6925 6.38357L13.6275 7.44857L11.505 5.32607L12.57 4.26107ZM6 10.8311L10.4475 6.38357L12.57 8.50607L8.1225 12.9536H6V10.8311Z"
                fill="#20419B"
              />
            </svg>
          </span>
        </div>
        <span @click="handleBackToList" class="fs-32 text-black py-2 cursor-pointer">&times;</span>
      </div>

      <div class="flex h-full mt-3" v-if="emrTemplateData">
        <MenuEmrTemplate @onSelectedDoc="(selectedDoc)=>docActive=selectedDoc" />
        <div class="w-full h-detail px-3 overflow-y-scroll relative">
          <DocumentTemplateList :emrTemplateData="emrTemplateData" />
        </div>
      </div>
    </div>
    <ModalAddEmrTemplate
      :isUpdate="true"
      :emrTemData="emrTemplateData"
      @onUpdateSuccess="()=>{
        showModalUpdateEmrTemplate(false)
        handleGetEmrTemplateDetail()
      }"
    />
  </div>
</template>

<script>
import MenuEmrTemplate from './MenuEmrTemplate.vue'
import ModalAddEmrTemplate from './ModalAddEmrTemplate.vue'
import DocumentTemplateList from './DocumentTemplate/DocumentTemplateList.vue'
import { MENU_EMR_TEMPLATE } from '../../utils/constants'

export default {
  name: 'EmrTemplateDetail',
  components: { MenuEmrTemplate, ModalAddEmrTemplate, DocumentTemplateList },
  data () {
    return {
      docActive: MENU_EMR_TEMPLATE[0].value,
      emrTemplateData: {},
      MENU_EMR_TEMPLATE
    }
  },
  beforeMount () {
    this.handleGetEmrTemplateDetail()
  },
  watch: {
    '$route.params.id' () {
      this.handleGetEmrTemplateDetail()
    }
  },
  computed: {
    isAuthor () {
      return this.$user && this.$user.id === this.emrTemplateData.author_id
    }
  },
  methods: {
    async handleGetEmrTemplateDetail () {
      const id = this.$route.params.id
      if (!id) return
      const response = await this.$rf
        .getRequest('DoctorRequest')
        .getEmrTemplateDetail(id)
      this.emrTemplateData = response.data?.data || {}
    },
    showModalUpdateEmrTemplate (show) {
      window.$('#modalAddEmrTemplate').modal(show ? 'show' : 'hide')
    },
    handleBackToList () {
      if (this.$route.query.vId && this.$route.query.tab) {
        this.$router
          .replace({
            query: {
              tab: this.$route.query.tab
            }
          })
          .catch(_ => {})
      } else {
        this.$router.replace({ name: 'EmrList' })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.h-detail {
  height: calc(100vh - 13rem);
}
</style>
