<template>
  <div class="min-h-full">
    <el-menu :default-active="selectedDoc" class="el-menu-cs" @select="handleOpen">
      <el-menu-item
        v-for="menuItem in MENU_EMR_TEMPLATE"
        :key="menuItem.value"
        :index="menuItem.value"
      >
        <span slot="title">{{ menuItem.label }}</span>
      </el-menu-item>
    </el-menu>
  </div>
</template>

<script>
import { MENU_EMR_TEMPLATE } from '../..//utils/constants'

export default {
  name: 'MenuEmrTemplate',

  data () {
    return {
      MENU_EMR_TEMPLATE,
      selectedDoc: MENU_EMR_TEMPLATE[0].value
    }
  },
  beforeMount () {
    this.handleSetSelectedTab()
  },
  watch: {
    '$route.query.tab' () {
      this.handleSetSelectedTab()
    }
  },
  methods: {
    handleOpen (selectedDoc) {
      if (selectedDoc === this.$route.query.tab) return
      this.$router.replace({ query: { tab: selectedDoc } })
    },
    handleSetSelectedTab () {
      if (this.$route.query.tab) {
        this.selectedDoc = this.$route.query.tab
        this.$emit('onSelectedDoc', this.$route.query.tab)
      } else {
        this.$router.replace({ query: { tab: MENU_EMR_TEMPLATE[0].value } })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .el-menu-cs:not(.el-menu--collapse) {
    width: 280px;
    height: 100%;
  }
  .el-menu--collapse {
    width: 48px;
  }
  .el-menu-item,
  .el-submenu__title,
  .el-submenu .el-menu-item {
    display: flex;
    align-items: center;
    height: unset;
    min-height: 40px;
    line-height: 32px;
    padding: 0 10px !important;
    white-space: pre-wrap;
  }

  .el-menu-item.is-active {
    background-color: #20409b !important;
  }
  .el-menu-item.is-active {
    color: white !important;
  }
  .el-menu-item:focus,
  .el-menu-item:hover {
    background-color: #e4e7ec;
  }
  .fliped {
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
  }
  .el-menu-item-group__title {
    padding: 0;
    height: 0;
  }
}
</style>