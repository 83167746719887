<template>
  <div>
    <div class="modal fade" id="modalAddEmrTemplate">
      <div v-loading="loading" class="modal-dialog modal-lg shadow" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5
              class="modal-title"
            >{{ $t(isUpdate ? "lbl_update_medical_record_template" : "lbl_add_medical_record_template") }}</h5>
          </div>
          <div class="modal-body txt-grey-900">
            <div class="mt-6 row">
              <div class="col-md-6">
                <label class="fs-14 fw-500">{{ $t('lbl_template_name_short') }}</label>
                <input
                  v-model="form.name"
                  type="text"
                  class="form--input fw-500 h-10"
                  :placeholder="$t('lbl_enter_template_name')"
                />
              </div>
              <div class="col-md-6">
                <label class="fs-14 fw-500">{{ $t('lbl_workspace') }}</label>
                <label
                  class="form--input fw-500 h-10 cursor-not-allowed flex items-center"
                >{{ $globalClinic? $globalClinic.name :'' }}</label>
              </div>
              <div class="col-md-6 mt-6">
                <label class="fs-14 fw-500">{{ $t('lbl_created_user') }}</label>
                <label
                  class="form--input fw-500 h-10 cursor-not-allowed flex items-center"
                >{{ form.created_by }}</label>
              </div>
              <div class="col-md-6 mt-6">
                <label class="fs-14 fw-500">{{ $t('lbl_created_date') }}</label>
                <label
                  class="form--input fw-500 h-10 cursor-not-allowed flex items-center"
                >{{ getDate(form.created_at) }}</label>
              </div>
            </div>
            <div class="mt-6">
              <div>{{ $t('lbl_select_type') }}</div>
              <el-select :disabled="!isAdmin" filterable v-model="form.is_public" class="col-6">
                <el-option :label="$t('lbl_for_workspace_full')" :value="1" />
                <el-option :label="$t('lbl_for_me_full')" :value="0" />
              </el-select>
            </div>
            <div class="mt-4 text-sm font-medium flex justify-end">
              <button
                @click="handleSubmit"
                class="flex items-center justify-center border-0 focus:outline-none mx-3 bg-blue-700 text-white rounded-md h-9 px-3 py-2"
              >
                <div v-if="!isUpdate">{{ $t('lbl_create_new') }}</div>
                <div v-else>{{ $t('lbl_update') }}</div>
              </button>
              <button
                @click="showModalAddEmrTemplate(false)"
                class="flex items-center justify-center border-0 focus:outline-none bg-blue-50 text-primary rounded-md h-9 px-3 py-2"
              >
                <div>{{ $t('lbl_cancel') }}</div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  <script>
const DEFAULT_FORM = {
  name: '',
  created_by: '',
  created_at: window.moment().format('YYYY-MM-DD'),
  is_public: 0
}

export default {
  name: 'ModalAddEmrTemplate',
  props: ['isUpdate', 'emrTemData'],
  data () {
    return {
      form: {
        ...DEFAULT_FORM
      },
      loading: ''
    }
  },
  computed: {
    isAdmin () {
      const isTrue =
        this.$globalClinic &&
        this.$user &&
        this.$user.doctor &&
        this.$globalClinic.doctor_id === this.$user.doctor.id

      if (!isTrue) this.form.is_public = 0

      return isTrue
    }
  },
  watch: {
    emrTemData () {
      this.handleMapData()
    }
  },
  mounted () {
    if (this.emrTemData && this.isUpdate) {
      this.handleMapData()
    }

    if (!this.isUpdate && this.$user) {
      this.form.created_by = this.$user.name
    }
  },
  methods: {
    showModalAddEmrTemplate (show) {
      window.$('#modalAddEmrTemplate').modal(show ? 'show' : 'hide')
    },
    getDate (date) {
      if (!date) return ''
      return window.moment(date).format('DD/MM/YYYY')
    },
    handleMapData () {
      this.form.name = this.emrTemData?.name
      this.form.created_by = this.emrTemData?.author?.name
      this.form.created_at = this.emrTemData?.created_at
      this.form.is_public = this.emrTemData?.is_public
    },
    handleSubmit () {
      if (this.isUpdate) {
        this.handleUpdateEmrTemplate()
      } else {
        this.handleCreateEmrTemplate()
      }
    },
    async handleCreateEmrTemplate () {
      if (!this.$globalClinic?.id) return

      if (!this.form.name) {
        this.$toast.open({
          message: 'Trường tên mẫu không được để thiếu',
          type: 'warning'
        })

        return
      }

      const params = {
        name: this.form.name,
        workspace_id: this.$globalClinic.id,
        is_public: this.form.is_public
      }
      await this.$rf.getRequest('DoctorRequest').postCreateEmrTemplate(params)

      this.form = { ...DEFAULT_FORM, created_by: this.$user.name }
      this.$toast.open({
        message: 'Tạo thành công',
        type: 'success'
      })
      this.$emit('onCreateSuccess')
    },
    async handleUpdateEmrTemplate () {
      if (!this.$globalClinic?.id || !this.emrTemData?.id) return

      const params = {
        name: this.form.name,
        workspace_id: this.$globalClinic.id,
        is_public: this.form.is_public
      }
      await this.$rf
        .getRequest('DoctorRequest')
        .postUpdateEmrTemplate(this.emrTemData.id, params)
      this.form = { ...DEFAULT_FORM, created_by: this.$user.name }
      this.$toast.open({
        message: 'Update thành công',
        type: 'success'
      })
      this.$emit('onUpdateSuccess')
    }
  }
}
</script>
  <style scoped></style>
  