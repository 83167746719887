import { MENU_EMR_TEMPLATE } from './constants'

export const mixinDocumentTemplate = {
  data () {
    return {}
  },
  methods: {
    handleGetTitle (dataType) {
      let title = MENU_EMR_TEMPLATE.find(item => item.value === dataType).label
      if (dataType === MENU_EMR_TEMPLATE[0].value) {
        title = 'Bệnh án ung bướu'
      }

      return title
    }
  }
}
